.good-savings-rate {
  color: var(--green);
}

.okay-savings-rate {
  color: var(--orange);
}

.negative-savings-rate {
  color: var(--red);
}
